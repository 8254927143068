import React from "react";

export const StarBurst = () => {
    
  return (
    <div className="starburst-outer">
      <div className="starburst-wrapper">
        <div className="starburst">
        <a href="https://blackjet.ca/santa/?utm_source=website&utm_medium=cta&utm_campaign=secret-santa" target="_blank">
        <div className="starburst-body">
          <span>Available now</span>
          <span className="starburst-main">Watch the<br/>making-of!</span>
        </div>
        </a>
      </div>

    </div>
    </div>
  );
};

export default StarBurst;
